import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { StaticPagesTemplate } from '../../components/common/static-pages-template';
import { ResourceMainImage } from '../../components/resource-main-image';

function StyleGuide({ data }) {
  return (
    <>
      <ResourceMainImage title="Style guide" />
      <StaticPagesTemplate data={data} />
    </>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/style-guide/"}) {
      content
      title
      uri
      ...SeoPage
    }
  }
`;

StyleGuide.propTypes = {
  data: object,
};

StyleGuide.defaultProps = {
  data: {},
};

export default StyleGuide;
export { Head } from '../../components/seo/seo';
